export default {
  toggleConfigurator(state) {
    state.showConfig = !state.showConfig;
  },
  sidebarMinimize(state) {
    let sidenav_show = document.querySelector("#app");
    if (state.isPinned) {
      sidenav_show.classList.add("g-sidenav-hidden");
      sidenav_show.classList.remove("g-sidenav-pinned");
      state.isPinned = false;
    } else {
      sidenav_show.classList.add("g-sidenav-pinned");
      sidenav_show.classList.remove("g-sidenav-hidden");
      state.isPinned = true;
    }
  },
  sidebarType(state, payload) {
    state.sidebarType = payload;
  },
  navbarFixed(state) {
    if (state.isNavFixed === false) {
      state.isNavFixed = true;
    } else {
      state.isNavFixed = false;
    }
  },

  SET_IS_ABSOLUTE(state, value) {
    state.isAbsolute = value;
  },
  SET_IMAGE_LAYOUT(state, layout) {
    state.imageLayout = layout;
  },
  SET_SHOW_NAVBAR(state, value) {
    state.showNavbar = value;
  },
  SET_SHOW_FOOTER(state, value) {
    state.showFooter = value;
  },
  SET_HIDE_CONFIG_BUTTON(state, value) {
    state.hideConfigButton = value;
  },
  SET_SHOW_SIDENAV(state, value){
    localStorage.setItem("showSidenav", `${value}`)
    state.showSidenav = value;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
};
