export default {
  toggleSidebarColor({ commit }, payload) {
    commit("sidebarType", payload);
  },
  updateIsAbsolute({ commit }, value) {
    commit("SET_IS_ABSOLUTE", value);
  },
  updateImageLayout({ commit }, layout) {
    commit("SET_IMAGE_LAYOUT", layout);
  },
  updateShowNavbar({ commit }, value) {
    commit("SET_SHOW_NAVBAR", value);
  },
  updateShowSidenav({ commit }, value) {
    commit("SET_SHOW_SIDENAV", value);
  },
  updateShowFooter({ commit }, value) {
    commit("SET_SHOW_FOOTER", value);
  },
  updateHideConfigButton({ commit }, value) {
    commit("SET_HIDE_CONFIG_BUTTON", value);
  },
  setLoading({ commit }, isLoading) {
    commit("SET_LOADING", isLoading);
  },
};
