import axios from "axios";
import { apiUrl } from "../api/enviroment";
import { ApiRoutes } from "../api/api_routes";
import TokenManager from "../token_manager";
import { useStore } from "vuex";

const ResultadosController = {
  async getResultados() {
    const store = useStore();
    const token = TokenManager.getToken();
    const pacienteId = store.state.$_user.PACIENTE_DATA.PacienteID;
    const url = `${apiUrl}/${ApiRoutes.getResultados}?PacienteID=${pacienteId}&Cantidad=100&salto=0`;

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return { ok: false };
      }
    } catch (error) {
      return { ok: false, error: error.message };
    }
  },

  async getResultadosPDF(labId) {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.resultadosBase64}${labId}`;

    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        return response.data; // Assuming response is the PDF in Base64
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  },
};

export default ResultadosController;
