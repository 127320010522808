<script setup>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { modulesName } from "@/store";
import AuthController from "../../utils/http/Authentication";
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const appState = computed(() => store.state[`${modulesName.appModuleName}`]);

const isRTL = computed(() => appState.value.isRTL);

const getRoute = () => {
  const routeArr = route.path.split("/");
  return routeArr[1];
};

function logOut() {
  store.dispatch(`${modulesName.appModuleName}/setLoading`, true);

  AuthController.logout()
    .then((response) => {
      if (response === true) {
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
        router.push("/login");
      } else {
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
        alert("No funciono");
      }
    })
    .catch((err) => {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      console.log(err);
      alert("Ocurrió un error:", err);
    });
}
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="'Dashboard'"
        >
          <template v-slot:icon>
            <img src="../../assets/img/icon-select.svg" alt="" />
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/resultados"
          :class="getRoute() === 'resultados' ? 'active' : ''"
          :navText="'Resultados'"
        >
          <template v-slot:icon>
            <img src="../../assets/img/icon-healt.svg" alt="" />
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/dependientes"
          :class="getRoute() === 'dependientes' ? 'active' : ''"
          :navText="'Dependientes'"
        >
          <template v-slot:icon>
            <img src="../../assets/img/icon-healt.svg" alt="" />
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/pre-factura"
          :class="getRoute() === 'pre-factura' ? 'active' : ''"
          :navText="'Pre-Factura'"
        >
          <template v-slot:icon>
            <img src="../../assets/img/icon-healt.svg" alt="" />
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Cuenta
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="'Perfil'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <argon-button fullWidth type="submit" @click="logOut" class="my-4 mb-2"
          >Salir</argon-button
        >
      </li>
    </ul>
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: '¿Necesita ayuda?',
        description: 'Contacte a nuestro equipo',
        links: [
          {
            label: 'Obtener Contacto',
            route: 'https://www.labreferencia.com/',
            color: 'dark',
          },
        ],
      }"
    />
  </div>
</template>
