<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
import AuthController from "../utils/http/Authentication";
import { modulesName } from "@/store";
import Swal from "sweetalert2";
import ProfileController from "../utils/http/Profile";
const store = useStore();

onBeforeMount(() => {
  store.dispatch(`${modulesName.appModuleName}/updateShowNavbar`, false);
  store.dispatch(`${modulesName.appModuleName}/updateShowSidenav`, false);
  store.dispatch(`${modulesName.appModuleName}/updateShowFooter`, false);
});
onBeforeUnmount(() => {
  store.dispatch(`${modulesName.appModuleName}/updateShowNavbar`, true);
  store.dispatch(`${modulesName.appModuleName}/updateShowSidenav`, true);
  store.dispatch(`${modulesName.appModuleName}/updateShowFooter`, true);
});

const router = useRouter();
const form = ref({
  password: "",
  email: "",
});

function goToRememberPassword() {
  router.push("/passwordreset");
}

function goToRegistry() {
  router.push("/signup");
}

function login() {
  store.dispatch(`${modulesName.appModuleName}/setLoading`, true);

  AuthController.loginAuth(form.value.email, form.value.password, true)
    .then(async (response) => {
      if (response != null) {
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
        var profileData = await ProfileController.getProfileData();

        store.dispatch(
          `${modulesName.userModuleName}/setPacienteData`,
          profileData
        );
        console.log(store.state.$_user.PACIENTE_DATA.PacienteID);
        router.push("/dashboard");
      } else {
        Swal.fire({
          title: "Usuario y/o contraseña estan mal",
          icon: "info",
        });
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      }
    })
    .catch((err) => {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      console.log(err);
      alert("Ocurrió un error:", err);
    });
}
</script>
<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-position: top; background-color: green"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="text-center mx-auto">
            <img src="@/assets/img/logorefe.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Inicio de Sesión</h5>
            </div>
            <div class="card-body">
              <form @submit.prevent="login">
                <argon-input
                  id="email"
                  :modelValue="form.email"
                  @update:modelValue="form.email = $event"
                  placeholder="Email"
                  aria-label="Email"
                />
                <argon-input
                  id="password"
                  :modelValue="form.password"
                  @update:modelValue="form.password = $event"
                  type="password"
                  placeholder="Contraseña"
                  aria-label="contraseña"
                />
                <p class="text-sm-right mt-3 mb-0">
                  <a
                    class="text-success"
                    style="cursor: pointer"
                    @click.prevent="goToRememberPassword"
                    >¿Has olvidado tu contraseña?</a
                  >
                </p>
                <div class="text-center">
                  <argon-button fullWidth type="submit" class="my-4 mb-2"
                    >Ingresar</argon-button
                  >
                </div>
                <p class="text-sm mt-3 mb-0">
                  ¿No tienes una cuenta?
                  <a
                    class="text-success"
                    style="cursor: pointer"
                    @click.prevent="goToRegistry"
                    >Regístrate</a
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
