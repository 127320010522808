const _env = process.env;

export const NODE_ENV = _env.NODE_ENV;

export const BASE_URL = _env.BASE_URL;

export const API = {
  BASE_URL: _env.VUE_APP_URL_API,
  TIMEOUT: 120000,
  TOKEN: _env.VUE_APP_TOKEN,
};
