<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
import AuthController from "../utils/http/Authentication";
import documentTypesConst from "../utils/const/documentType";
import { modulesName } from "@/store";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();

const documentTypes = ref([
  documentTypesConst.RNC,
  documentTypesConst.CEDULA,
  documentTypesConst.PASAPORTE,
  documentTypesConst.CEDULA_MENOR,
]);

const form = ref({
  name: "",
  lastname: "",
  email: "",
  password: "",
  documentType: 1,
  identificationNumber: "",
});

const formErrors = ref({
  name: "",
  lastname: "",
  email: "",
  password: "",
  documentType: null,
  identificationNumber: "",
});

// Validaciones simples
function validateForm() {
  formErrors.value = {}; // Reiniciar errores

  if (!form.value.name) {
    formErrors.value.name = "El nombre es requerido";
  }
  if (!form.value.lastname) {
    formErrors.value.lastname = "El apellido es requerido";
  }
  if (!form.value.email) {
    formErrors.value.email = "El correo electrónico es requerido";
  } else if (!/\S+@\S+\.\S+/.test(form.value.email)) {
    formErrors.value.email = "El correo electrónico no es válido";
  }
  if (!form.value.password) {
    formErrors.value.password = "La contraseña es requerida";
  }
  if (!form.value.documentType) {
    formErrors.value.documentType = "El tipo de documento es requerido";
  }
  if (!form.value.identificationNumber) {
    formErrors.value.identificationNumber =
      "El número de identificación es requerido";
  }

  // Si hay errores, devolver false
  return Object.keys(formErrors.value).length === 0;
}

async function login() {
  if (!validateForm()) {
    Swal.fire({
      title: "Errores en el formulario",
      text: "Por favor, verifica los campos.",
      icon: "error",
    });
    return;
  }

  store.dispatch(`${modulesName.appModuleName}/setLoading`, true);
  AuthController.registerUser(
    form.value.name,
    form.value.lastname,
    form.value.email,
    form.value.password,
    form.value.identificationNumber,
    form.value.documentType
  )
    .then((response) => {
      if (response.status == 200) {
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
        //llamo el login desde aca para que usuario inicie sesion inmediatamente
        AuthController.loginAuth(form.value.email, form.value.password, true)
          .then((response) => {
            if (response != null) {
              store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
              router.push("/dashboard");
            } else {
              Swal.fire({
                title: "Usuario y/o contraseña estan mal",
                icon: "info",
              });
              store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
            }
          })
          .catch((err) => {
            store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
            alert("Ocurrió un error:", err);
          });
      } else {
        Swal.fire({
          title: "Error al registrar Usuario",
          icon: "info",
        });
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      }
    })
    .catch((err) => {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      Swal.fire({
        title: "Ocurrió un error",
        text: err,
        icon: "error",
      });
    });
}

onBeforeMount(() => {
  store.dispatch(`${modulesName.appModuleName}/updateShowNavbar`, false);
  store.dispatch(`${modulesName.appModuleName}/updateShowSidenav`, false);
  store.dispatch(`${modulesName.appModuleName}/updateShowFooter`, false);
});

onBeforeUnmount(() => {
  store.dispatch(`${modulesName.appModuleName}/updateShowNavbar`, true);
  store.dispatch(`${modulesName.appModuleName}/updateShowSidenav`, true);
  store.dispatch(`${modulesName.appModuleName}/updateShowFooter`, true);
});

function goToLogin() {
  router.push("/login");
}
</script>

<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-color: green"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="text-center mx-auto">
            <img src="@/assets/img/logorefe.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Registro</h5>
            </div>
            <div class="card-body">
              <form @submit.prevent="login">
                <argon-input
                  id="name"
                  :modelValue="form.name"
                  @update:modelValue="form.name = $event"
                  placeholder="Nombre"
                  aria-label="Nombre"
                />
                <span v-if="formErrors.name" class="text-danger">{{
                  formErrors.name
                }}</span>

                <argon-input
                  id="lastname"
                  :modelValue="form.lastname"
                  @update:modelValue="form.lastname = $event"
                  placeholder="Apellido"
                  aria-label="Apellido"
                />
                <span v-if="formErrors.lastname" class="text-danger">{{
                  formErrors.lastname
                }}</span>

                <argon-input
                  id="email"
                  :modelValue="form.email"
                  @update:modelValue="form.email = $event"
                  placeholder="Email"
                  aria-label="Email"
                />
                <span v-if="formErrors.email" class="text-danger">{{
                  formErrors.email
                }}</span>

                <div class="form-group">
                  <select
                    class="form-control"
                    id="documentType"
                    v-model="form.documentType"
                    placeholder="Tipo de documento"
                    value="cedula"
                  >
                    <option
                      v-for="(item, i) in documentTypes"
                      :key="i"
                      :value="item.id"
                    >
                      {{ item.value }}
                    </option>
                  </select>
                  <span v-if="formErrors.documentType" class="text-danger">{{
                    formErrors.documentType
                  }}</span>
                </div>

                <argon-input
                  id="identificationNumber"
                  :modelValue="form.identificationNumber"
                  @update:modelValue="form.identificationNumber = $event"
                  placeholder="Número de documento"
                />
                <span
                  v-if="formErrors.identificationNumber"
                  class="text-danger"
                  >{{ formErrors.identificationNumber }}</span
                >

                <argon-input
                  id="password"
                  :modelValue="form.password"
                  @update:modelValue="form.password = $event"
                  type="password"
                  placeholder="Contraseña"
                  aria-label="Contraseña"
                />
                <span v-if="formErrors.password" class="text-danger">{{
                  formErrors.password
                }}</span>

                <div class="text-center">
                  <argon-button fullWidth type="submit" class="my-4 mb-2"
                    >Registrar</argon-button
                  >
                </div>

                <p class="text-sm mt-3 mb-0">
                  ¿Tienes una cuenta?
                  <a
                    class="text-success font-weight-bolder"
                    style="cursor: pointer"
                    @click.prevent="goToLogin"
                    >Inicia sesión</a
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
