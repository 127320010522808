import axios from 'axios';
import { ApiRoutes } from '../api/api_routes';
import TokenManager from '../token_manager';
import { apiUrl } from '../../enviroments/enviroment';

const AuthController = {

  async loginAuth(user, password, recordarme) {
    const url = `${apiUrl}/${ApiRoutes.login}`;
    try {
      const response = await axios.post(url, {
        Username: user,
        PWD: password,
        Recordar: recordarme,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        const token = response.data;
         TokenManager.saveToken(token);
        return token;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },

  async logout() {
    
    const url = `${apiUrl}${ApiRoutes.logOut}`;
    
    const token =  TokenManager.getToken();
    
    try {
      
      const response = await axios.post(url, {},  
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

      if (response.status === 200) {
      
        TokenManager.deleteToken();
      
        return true;
      } 
      else 
      {
        return false;
      }

    } catch (error) {
      console.log(error)
      return false;
    }
  },
  
  async recoverPassword(user, otp, newPassword, confirmPassword) {
    
    const url = `${apiUrl}/${ApiRoutes.changePasswordForgotPassword}`;
    
    try {
      const response = await axios.post(url, {
        UserNameEmail: user,
        PasswordNew: newPassword,
        PasswordConfirm: confirmPassword,
        OTP: otp
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.status === 200;
    
    } catch (error) {
      
      return false;
    }
  },

  async forgotPasswordSendEmail(user) {
    const url = `${apiUrl}/${ApiRoutes.solicitarForgotPassword}${user}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      return response.status === 200 ? true : false;

    } catch (error) {
      return null;
    }
  },

  async registerUser(Nombre, Apellido, UserNameEmail, Password, Codigo, IdentificacionNumero, IdentificacionTipo) {
    const url = `${apiUrl}/${ApiRoutes.registrarUsuario}`;
    try {
      const response = await axios.post(url, {
        Nombre,
        Apellido,
        UserNameEmail,
        Password,
        Codigo,
        IdentificacionNumero,
        IdentificacionTipo
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return response;
    } catch (error) {
      return error;
    }
  }
};

export default AuthController;
