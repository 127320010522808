import axios from "axios";
import { API, NODE_ENV } from "@/config";

export const api = axios.create({
  baseURL: "https://ppacienteqa.azurewebsites.net", //API.BASE_URL,
  timeout: NODE_ENV === "production" ? API.TIMEOUT : undefined,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: `Bearer ${API.TOKEN}`,
  },
});
