<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Gesti&oacute;n de Dependientes</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <tbody>
            <tr v-for="dependiente in dependientes" :key="dependiente.Identificacion">
              <td>
                <div class="d-flex px-2 py-1 align-items-center">
                  <img
                    src="../../assets/img/lab-ref-logo.svg"
                    class="avatar avatar-sm me-2"
                    alt="user1"
                  />
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ dependiente.NombreCompleto }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ dependiente.Identificacion }}
                    </p>
                    <p class="text-xs text-secondary mb-0">
                      {{ dependiente.DecripcionParentezco }}
                    </p>
                  </div>
                  <!-- <div class="ms-auto d-flex justify-content-center">
                    <button 
                      class="btn btn-outline-success btn-sm me-1" 
                      @click="modificarDependiente(dependiente.DependienteId)"
                      title="Modificar"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button 
                      class="btn btn-outline-danger btn-sm" 
                      @click="eliminarDependiente(dependiente.DependienteId)"
                      title="Eliminar"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DependientesController from '../../utils/http/Dependientes';
import { useStore } from "vuex";
import { modulesName } from "@/store";

export default {
  data() {
    return {
      dependientes: []
    };
  },
  async created() {
    await this.fetchDependientes();
  },
  methods: {
    async fetchDependientes() {
      
      const store = useStore();
      
      store.dispatch(`${modulesName.appModuleName}/setLoading`, true);
      
      const result = await DependientesController.getDependientes();
      
      if (result && result.Dependientes) {
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
        this.dependientes = result.Dependientes;
      } else {
        store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
        console.error("Error al obtener dependientes", result.error || "No se encontraron dependientes");
      }
    
    }
  }
};
</script>
