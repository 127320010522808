const _state = {
  user: null,
  accessToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkhFTlJZNjk4MiIsInJvbGUiOiJBZG0iLCJuYmYiOjE3MjMxNTM0MDMsImV4cCI6MTcyMzM2OTQwMywiaWF0IjoxNzIzMTUzNDAzLCJpc3MiOiJodHRwczovL3BwYWNpZW50ZXFhLmF6dXJld2Vic2l0ZXMubmV0LyIsImF1ZCI6Imh0dHBzOi8vcHBhY2llbnRlcWEuYXp1cmV3ZWJzaXRlcy5uZXQvIn0.3-864XHxCv8jOZOjpXMT_Z9QC63-zddf0UsHXaifxQw",
  PACIENTE_DATA: null,
};

export const initialState = () => _state;

export const state = initialState();
