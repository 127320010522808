<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import { modulesName } from "@/store";
import NotificacionesController from "../../utils/http/Notificaciones";

const showMenu = ref(false);
const notificaciones = ref([]);
const store = useStore();
const appState = computed(() => store.state[`${modulesName.appModuleName}`]);
const isRTL = computed(() => appState.value.isRTL);
const fullName = ref(
  store.state.$_user.PACIENTE_DATA.Nombres +
    " " +
    store.state.$_user.PACIENTE_DATA.Apellidos
);
const route = useRoute();

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");
const toggleConfigurator = () => store.commit("toggleConfigurator");

const closeMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 100);
};

const fetchData = async () => {
  try {
    const response = await NotificacionesController.getNotificaciones();
    notificaciones.value = response.filter((x) => x.ESLEIDO == false);
  } catch (error) {
    console.error("Error fetching notificaciones:", error);
  }
};

function formatDateDiff(date1, date2) {
  // Asegúrate de que date1 y date2 son instancias de Date
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    throw new Error("Ambos argumentos deben ser instancias de Date.");
  }

  // Calcula la diferencia en milisegundos
  const diffInMs = Math.abs(date2 - date1);

  // Convierte la diferencia a segundos
  const diffInSeconds = Math.floor(diffInMs / 1000);

  // Calcula los días, horas, minutos y segundos
  const days = Math.floor(diffInSeconds / (24 * 3600));
  const hours = Math.floor((diffInSeconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = diffInSeconds % 60;

  // Construye el resultado en formato legible
  let result = "";
  if (days > 0) result += `${days} Días `;
  if (hours > 0 || days > 0) result += `${hours} Horas `;
  if (minutes > 0 || hours > 0 || days > 0) result += `${minutes} Minutos `;
  if (seconds > 0 || minutes > 0 || hours > 0 || days > 0)
    result += `${seconds} Segundos`;

  return result.trim();
}
async function showNotificationMenu() {
  showMenu.value = !showMenu.value;
  if (showMenu.value === true) {
    notificaciones.value.forEach((element) => {
      NotificacionesController.setAsRead(element.ID);
    });
    await fetchData();
  }
}

onMounted(fetchData);
</script>
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <div class="px-0 nav-link font-weight-bold text-white">
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-2'"></i>
              <span class="d-sm-inline d-none">{{ fullName }}</span>
            </div>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="minimizeSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="toggleConfigurator">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link text-white"
              :class="[showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showNotificationMenu()"
              @blur="closeMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li
                class="mb-2"
                v-for="(item, index) in notificaciones"
                :key="index"
              >
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="https://cdn-icons-png.freepik.com/512/4226/4226663.png"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold"
                          >Nueva Notificaci&oacute;n:</span
                        >
                        {{ item.MENSAJE }}
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{
                          formatDateDiff(
                            new Date(),
                            new Date(item.FECHANOTIFICACION)
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
