import types from "./types";

export default {
  [types.SET_USER](state, user) {
    state.user = user;
  },
  SET_PACIENTE_DATA(state, data) {
    state.PACIENTE_DATA = data;
  },
};
