<script setup>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { modulesName } from "../store";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
import AuthController from "../utils/http/Authentication";
import Swal from "sweetalert2";

const store = useStore();

onBeforeMount(() => {
  store.dispatch(`${modulesName.appModuleName}/updateShowNavbar`, false);
  store.dispatch(`${modulesName.appModuleName}/updateShowSidenav`, false);
  store.dispatch(`${modulesName.appModuleName}/updateShowFooter`, false);
});

const router = useRouter();

const form = ref({
  email: "",
});

function goToLogin() {
  router.push("/login");
}

function forgotPasswordSendEmail() {
  store.dispatch(`${modulesName.appModuleName}/setLoading`, true);

  let user = form.value.email;

  AuthController.forgotPasswordSendEmail(user).then((res) => {
    if (res) {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);

      Swal.fire({
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
        allowEscapeKey: false,
        backdrop: true,
        allowOutsideClick: false,
        icon: "success",
        text: "El enlace para restablecer su contraseña ha sido enviado",
        confirmButtonText: "Volver a la pagina para iniciar sesion",
        footer: '<a href="/passwordreset">¿Aun no recibiste el correo?</a>',
      }).then((res) => {
        if (res.isConfirmed) {
          goToLogin();
        }
      });
    } else {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      Swal.fire({
        title: "Algo estan mal",
        icon: "error",
      });
    }
  });
}
</script>

<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-position: top; background-color: green"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="text-center mx-auto">
            <img src="@/assets/img/logorefe.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Restablecer Contraseña</h5>
              <p class="text-sm mt-3 mb-0">
                Ingrese la direccion de correo electronico asociada con su
                cuenta y le enviaremos un enlace para restablecer su Contraseña
              </p>
            </div>
            <div class="card-body">
              <form @submit.prevent="forgotPasswordSendEmail">
                <argon-input
                  id="email"
                  :modelValue="form.email"
                  @update:modelValue="form.email = $event"
                  placeholder="Usuario"
                  aria-label="Email"
                />
                <div class="text-center">
                  <argon-button fullWidth type="submit" class="my-4 mb-2"
                    >Restablecer</argon-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
