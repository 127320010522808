const _state = {
  hideConfigButton: false,
  isPinned: false,
  showConfig: false,
  sidebarType: "bg-white",
  isRTL: false,
  mcolor: "",
  darkMode: false,
  isNavFixed: false,
  isAbsolute: false,
  showNavs: false,
  showSidenav: localStorage.getItem("showSidenav")??false,
  showNavbar: false,
  showFooter: false,
  showMain: false,
  layout: "default",
  isLoading: false,
};

export const initialState = () => _state;

export const state = initialState();
