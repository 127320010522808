<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Historial de Resultados</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <tbody>
            <tr
              v-for="resultado in resultados"
              :key="resultado.NumeroLaboratorio"
            >
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      src="../../assets/img/lab-ref-logo.svg"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      Nro. de reserva #{{ resultado.NumeroLaboratorio }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">
                      <strong>Nombre:</strong> {{ resultado.NombrePaciente }}
                    </p>
                    <p class="text-xs text-secondary mb-0">
                      <strong>Fecha:</strong>
                      {{ formatDate(resultado.FechaEntrada) }}
                    </p>
                  </div>
                  <div class="d-flex">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png"
                      @click="shareOnWhatsApp(resultado.NumeroLaboratorio)"
                      style="
                        width: 30px;
                        height: 30px;
                        margin-left: 20px;
                        cursor: pointer;
                      "
                      alt="Compartir en WhatsApp"
                    />
                  </div>
                </div>
              </td>
              <td>
                <span class="badge badge-sm bg-gradient-success"
                  >{{ resultado.CantidadPruebasRealizadas }}/{{
                    resultado.CantidadPruebas
                  }}</span
                >
              </td>
              <td class="align-middle">
                <button
                  style="border: 0; background: none"
                  class="text-secondary font-weight-bold text-xs"
                  @click="visualizarResultado(resultado.NumeroLaboratorio)"
                >
                  Visualizar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ResultadosController from "../../utils/http/Resultados";
import { apiUrl } from "../../utils/api/enviroment";
import { ApiRoutes } from "../../utils/api/api_routes";
import TokenManager from "../../utils/token_manager";
import axios from "axios";

export default {
  data() {
    return {
      resultados: [],
    };
  },
  async created() {
    await this.fetchResultados();
  },
  methods: {
    async fetchResultados() {
      const result = await ResultadosController.getResultados();
      if (result.ok !== false) {
        this.resultados = result;
      } else {
        console.error(
          "Error al obtener resultados",
          result.error || "No se encontraron resultados"
        );
      }
    },

    async getPdfLink(NumeroLaboratorio) {
      try {
        const token = TokenManager.getToken();
        const url = `${apiUrl}/${ApiRoutes.PdfResultados}?NoLaboratorio=${NumeroLaboratorio}`;

        const response = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const baseUrl = "https://ppacienteqa.azurewebsites.net";
        return `${baseUrl}${response.data}`; // Devuelve el enlace completo del PDF
      } catch (error) {
        console.error("Error al obtener el enlace del PDF:", error);
        return null;
      }
    },

    // Método para visualizar el PDF en una nueva pestaña
    async visualizarResultado(NumeroLaboratorio) {
      const pdfLink = await this.getPdfLink(NumeroLaboratorio);
      if (pdfLink) {
        window.open(pdfLink, "_blank");
      } else {
        console.error("No se pudo abrir el enlace del PDF");
      }
    },

    async shareOnWhatsApp(NumeroLaboratorio) {
      const pdfLink = await this.getPdfLink(NumeroLaboratorio);
      if (pdfLink) {
        const text = encodeURIComponent(
          `Visualiza estos resultados de Laboratorios Referencia: ${pdfLink}`
        );
        const shareUrl = `https://api.whatsapp.com/send?text=${text}`;
        window.open(shareUrl, "_blank");
      } else {
        console.error(
          "No se pudo obtener el enlace para compartir en WhatsApp"
        );
      }
    },

    formatDate(fecha) {
      const date = new Date(fecha);
      return date.toLocaleDateString();
    },
  },
};
</script>
