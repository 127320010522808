<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { modulesName } from "@/store";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";
import UserCard from "./components/UserCard.vue";
import { ref } from "vue";
import ProfileController from "../utils/http/Profile";
import * as yup from "yup";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const profileData = ref({});
function formatDate(fecha) {
  const date = new Date(fecha);
  return date.toLocaleDateString();
}
const validationSchema = yup.object().shape({
  Email: yup
    .string()
    .email("El correo electrónico debe ser válido")
    .required("El correo electrónico es obligatorio"),
  Movil: yup.string().required("El Móvil obligatorio"),
  Telefono: yup.string().required("El Teléfono es obligatorio"),
});
const updateProfile = async () => {
  const isValid = await validationSchema
    .validate({
      Email: profileData.value.Email,
      Telefono: profileData.value.Telefono,
      Movil: profileData.value.Movil,
    })
    .catch((err) => err);
  if (isValid instanceof yup.ValidationError) {
    Swal.fire({
      title: "Error",
      text: "Por favor, verifica los campos.",
      icon: "error",
    });
  } else {
    await ProfileController.updateProfileInfo(
      profileData.value.Identificacion,
      profileData.value.Sexo,
      profileData.value.Movil,
      profileData.value.Telefono,
      profileData.value.Email
    );
    Swal.fire({
      title: "",
      text: "La información ha sido actualizada exitosamente.",
      icon: "success",
    });
  }
};
onMounted(async () => {
  store.dispatch(`${modulesName.appModuleName}/updateIsAbsolute`, true);
  setNavPills();
  setTooltip();
  var data = await ProfileController.getProfileData();
  data.FechaNacimiento = formatDate(data.FechaNacimiento);
  profileData.value = data;
});
onBeforeMount(() => {
  store.dispatch(
    `${modulesName.appModuleName}/updateImageLayout`,
    "profile-overview"
  );
  store.dispatch(`${modulesName.appModuleName}/updateShowNavbar`, false);
  store.dispatch(`${modulesName.appModuleName}/updateShowFooter`, true);
  store.dispatch(`${modulesName.appModuleName}/updateHideConfigButton`, true);
  body.classList.add("profile-overview");
});
onBeforeUnmount(() => {
  store.dispatch(`${modulesName.appModuleName}/updateIsAbsolute`, false);
  store.dispatch(`${modulesName.appModuleName}/updateImageLayout`, "default");
  store.dispatch(`${modulesName.appModuleName}/updateShowNavbar`, true);
  store.dispatch(`${modulesName.appModuleName}/updateShowFooter`, true);
  store.dispatch(`${modulesName.appModuleName}/updateHideConfigButton`, false);
  body.classList.remove("profile-overview");
});
</script>
<template>
  <main>
    <div class="container-fluid">
      <div
        class="page-header min-height-300"
        style="
          background-image: url(&quot;../assets/img/bg-ref.png&quot;);
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <!-- <span class="mask bg-gradient-success opacity-6"></span> -->
      </div>
      <user-card style="margin-top: -60px"></user-card>
    </div>
    <div class="py-4 container-fluid">
      <VeeForm :validation-schema="validationSchema" as="div">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header pb-0">
                <div class="d-flex align-items-center">
                  <p class="mb-0">Editar Perfil</p>
                </div>
              </div>
              <div class="card-body">
                <p class="text-uppercase text-sm">Información del Usuario</p>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-control-label">Correo Electrónico</label>
                    <Field
                      type="email"
                      name="Email"
                      class="form-control"
                      v-model="profileData.Email"
                    />
                    <ErrorMessage name="Email" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Nombre(s)</label>
                    <input
                      v-model="profileData.Nombres"
                      class="form-control"
                      readonly
                      type="text"
                      value=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Apellido(s)</label>
                    <input
                      class="form-control"
                      v-model="profileData.Apellidos"
                      readonly
                      type="text"
                      value=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"
                      >Identificaci&oacute;n</label
                    >
                    <input
                      class="form-control"
                      readonly
                      type="text"
                      v-model="profileData.Identificacion"
                      value=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"
                      >Fecha de Nacimiento</label
                    >
                    <input
                      class="form-control"
                      readonly
                      type="text"
                      value=""
                      v-model="profileData.FechaNacimiento"
                    />
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">
                  Informaci&oacute;n de Contacto
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-control-label">Teléfono Movil</label>
                    <Field
                      type="text"
                      name="Movil"
                      class="form-control"
                      v-model="profileData.Movil"
                    />
                    <ErrorMessage name="Movil" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"
                      >Teléfono Residencial</label
                    >
                    <Field
                      type="text"
                      name="Telefono"
                      class="form-control"
                      v-model="profileData.Telefono"
                    />
                    <ErrorMessage name="Telefono" />
                  </div>

                  <div class="col-md-12">
                    <argon-button
                      color="success"
                      variant="fill"
                      size="sm"
                      class="mt-4"
                      @click="updateProfile"
                      >Guardar</argon-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VeeForm>
    </div>
  </main>
</template>
<style scoped>
span[role="alert"] {
  color: red;
  font-size: 12px;
}
</style>
