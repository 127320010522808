import { api } from "@/core/api";

export const getUser = () => {
  return api.get("api/LoginApp/GetPacienteLog");
};

export const login = (payload) => {
  return api.post("/api/LoginApp/login", payload);
};

export const logout = () => {
  return api.post("api/LoginApp/LogOut");
};
