import axios from "axios";
import { apiUrl } from "../api/enviroment";
import { ApiRoutes } from "../api/api_routes";
import TokenManager from "../token_manager";

const ProfileController = {
  async getProfileData() {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.getPacienteLog}`;

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const resp = response.data;
        localStorage.setItem("pacienteId", resp["PacienteID"]);
        return resp;
      } else {
        return { ok: false };
      }
    } catch (error) {
      return { ok: false, error: error.message };
    }
  },

  async updateProfileInfo(
    Identificacion,
    Sexo,
    TelefonoMovil,
    TelefonoResidencial,
    Email
  ) {
    const token = TokenManager.getToken();
    const pacienteId = localStorage.getItem("pacienteId");
    const url = `${apiUrl}/${ApiRoutes.updatePersonalInfo}`;

    try {
      const response = await axios.post(
        url,
        {
          PacienteId: pacienteId,
          Identificacion: Identificacion,
          Sexo: Sexo,
          TelefonoMovil: TelefonoMovil,
          TelefonoResidencial: TelefonoResidencial,
          Correo: Email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.status === 200;
    } catch (error) {
      return false;
    }
  },

  async changePassword(current, newPassword, confirmPassword) {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.cambiarContrasenaLog}`;

    try {
      const response = await axios.post(
        url,
        {
          PasswordOld: current,
          PasswordNew: newPassword,
          PasswordNewConfirm: confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.status === 200;
    } catch (error) {
      return false;
    }
  },

  async updateImage(username, base64) {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.uploadUserImage}?username=${username}&imagenBase64=${base64}`;

    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.status === 200;
    } catch (error) {
      return false;
    }
  },
};

export default ProfileController;
