<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { modulesName } from "@/store";

const store = useStore();
const appState = computed(() => store.state[`${modulesName.appModuleName}`]);

const layout = computed(() => appState.value.layout);

defineProps({
  card: {
    type: Object,
    required: true,
    title: String,
    description: String,
    links: {
      type: Array,
      label: String,
      route: String,
      color: String,
    },
  },
});
</script>
<template>
  <div
    v-show="layout !== 'landing'"
    class="card card-plain shadow-none"
    id="sidenavCard"
  >
    <div class="p-3 card-body text-center w-100 pt-0">
      <img
        class="w-50 mx-auto"
        src="@/assets/img/need_help.svg"
        alt="sidebar_illustration"
      />

      <h6 class="mb-0 text-dark up">¿Necesita ayuda?</h6>

      <p class="text-xs font-weight-bold">Contacte a nuestro equipo</p>
    </div>

    <a
      href="https://www.labreferencia.com/"
      target="_blank"
      class="mb-3 btn btn-dark btn-sm w-100"
    >
      Obtener Contacto
    </a>
  </div>
</template>
