import types from "./types";
import { getUser, login as _login, logout as _logout } from "@/core/auth";

export default {
  async init(context) {
    const { commit } = context;

    try {
      const { data: user } = await getUser();
      commit(types.SET_USER, user);
    } catch (error) {
      console.log(error);
      commit(types.SET_USER, null);
    }
  },
  login() {
    _login();
  },
  logout() {
    _logout();
  },
  setPacienteData({ commit }, data) {
    commit("SET_PACIENTE_DATA", data);
  },
};
