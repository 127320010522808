<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { modulesName } from "@/store";

const store = useStore();
const appState = computed(() => store.state[`${modulesName.appModuleName}`]);
</script>
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="appState.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}, Todos los derechos reservados,
            Laboratorios Referencia.
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          ></ul>
        </div>
      </div>
    </div>
  </footer>
</template>
