<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 mb-lg" style="margin-top: 10%">
        <user-card></user-card>
        <div class="row">
          <div class="col-lg-12 mb-lg d-none">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line"
                title="Gr&aacute;fico de resultados"
                description="<i class='fa fa-arrow-up text-success'></i> <span class='font-weight-bold'>(+5) mejoras</span> en los &uacute;ltimos 3 ex&aacute;menes"
                :chart="{
                  labels: [
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: [
                    {
                      label: 'Hemoglobina',
                      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                    },
                  ],
                }"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">&Uacute;ltimos Resultados</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(resultado, index) in resultados" :key="index">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div>
                            <img src="../assets/img/filter.svg" />
                          </div>
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold">
                              N&uacute;mero de Laboratorio:
                            </p>
                            <h6 class="mb-0 text-sm">
                              {{ resultado.NumeroLaboratorio }}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">
                            Resultados Disponibles:
                          </p>
                          <h6 class="mb-0 text-sm">
                            {{ resultado.CantidadPruebasRealizadas }}/{{
                              resultado.CantidadPruebas
                            }}
                          </h6>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Fecha:</p>
                          <h6 class="mb-0 text-sm">
                            {{ formatDate(resultado.FechaEntrada) }}
                          </h6>
                        </div>
                      </td>
                      <td class="text-sm align-middle">
                        <div class="text-center col">
                          <button
                            style="border: 0; background: none"
                            @click="
                              visualizarResultado(resultado.NumeroLaboratorio)
                            "
                          >
                            <img
                              src="../assets/img/Icon.png"
                              style="cursor: pointer"
                              alt=""
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <TablaNotificaciones :notificaciones="notificaciones" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import Carousel from "./components/Carousel.vue";
import TablaNotificaciones from "./components/TablaNotificaciones.vue";
import UserCard from "./components/UserCard.vue";
import ResultadosController from "../utils/http/Resultados";
import NotificacionesController from "../utils/http/Notificaciones";
import TokenManager from "../utils/token_manager";
import { ApiRoutes } from "../utils/api/api_routes";
import { apiUrl } from "../enviroments/enviroment";
import axios from "axios";

export default {
  components: {
    GradientLineChart,
    TablaNotificaciones,
    UserCard,
  },
  computed: {},
  data() {
    return {
      resultados: [],
      notificaciones: [],
    };
  },
  async created() {
    await this.fetchResultados();
    await this.fetchNotificaciones();
  },
  methods: {
    async fetchResultados() {
      const result = await ResultadosController.getResultados();
      if (result.ok !== false) {
        this.resultados = result.slice(0, 5);
      } else {
        console.error(
          "Error al obtener resultados",
          result.error || "No se encontraron resultados"
        );
      }
    },
    async fetchNotificaciones() {
      const result = await NotificacionesController.getNotificaciones();
      if (result.ok !== false) {
        this.notificaciones = result.map((x) => ({
          icon: {
            component: "ni ni-notification-70",
            background: "dark",
          },
          label: x.MENSAJE,
          description: this.formatDate(x.FECHANOTIFICACION),
        }));
      } else {
        console.error(
          "Error al obtener resultados",
          result.error || "No se encontraron resultados"
        );
      }
    },

    async getPdfLink(NumeroLaboratorio) {
      try {
        const token = TokenManager.getToken();
        const url = `${apiUrl}/${ApiRoutes.PdfResultados}?NoLaboratorio=${NumeroLaboratorio}`;

        const response = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const baseUrl = "https://ppacienteqa.azurewebsites.net";
        return `${baseUrl}${response.data}`; // Devuelve el enlace completo del PDF
      } catch (error) {
        console.error("Error al obtener el enlace del PDF:", error);
        return null;
      }
    },

    // Método para visualizar el PDF en una nueva pestaña
    async visualizarResultado(NumeroLaboratorio) {
      const pdfLink = await this.getPdfLink(NumeroLaboratorio);
      if (pdfLink) {
        window.open(pdfLink, "_blank");
      } else {
        console.error("No se pudo abrir el enlace del PDF");
      }
    },

    async shareOnWhatsApp(NumeroLaboratorio) {
      const pdfLink = await this.getPdfLink(NumeroLaboratorio);
      if (pdfLink) {
        const text = encodeURIComponent(
          `Visualiza estos resultados de Laboratorios Referencia: ${pdfLink}`
        );
        const shareUrl = `https://api.whatsapp.com/send?text=${text}`;
        window.open(shareUrl, "_blank");
      } else {
        console.error(
          "No se pudo obtener el enlace para compartir en WhatsApp"
        );
      }
    },

    formatDate(fecha) {
      const date = new Date(fecha);
      return date.toLocaleDateString();
    },
  },
};
</script>
<style>
.glass {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
</style>
