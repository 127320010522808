import axios from "axios";
import { apiUrl } from "../api/enviroment";
import { ApiRoutes } from "../api/api_routes";
import TokenManager from "../token_manager";

const NotificacionesController = {
  async getNotificaciones() {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.notificacionesMoviles}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return { ok: false };
      }
    } catch (error) {
      return { ok: false, error: error.message };
    }
  },
  async setAsRead(id) {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.marcarNotificacionesComoLeidas}?idNotificacion=${id}`;
    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return { ok: false };
      }
    } catch (error) {
      return { ok: false, error: error.message };
    }
  },
};

export default NotificacionesController;
