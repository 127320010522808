const ApiRoutes = {
  login: "LoginApp/login",
  sucursales: "Sucursales/GetLatitudLongitudAll",
  notificaciones: "Notificacciones/GetAll",
  preFacturaGetAll: "PreFactura/GetAll",
  logOut: "LoginApp/LogOut",
  dependientesGetAll: "PACIENTE/ListarPAcienteConDependientes",
  getPacienteLog: "LoginApp/GetPacienteLog",
  getResultados: "Resultados/GetResultadosByPacienteId",
  registrarUsuario: "Registrar/RegistrarUsuario",
  notificacionesMoviles: "NotificaccionesMoviles/GetAll",
  marcarNotificacionesComoLeidas: "NotificaccionesMoviles/MarcarLeido",
  cambiarContrasenaLog: "Registrar/CambiarContrasenaLog",
  solicitarForgotPassword: "Registrar/SolicitudOlvideContrasena?user=",
  changePasswordForgotPassword: "Registrar/OlvideContrasena",
  updatePersonalInfo: "PACIENTE/ModificarDatosPaciente",
  resultadosBase64: "Resultados/ResultadosURLByNoLaboratorios?NoLaboratorio=",
  registerPreFacturaIndicacion: "PreFactura/SetPrefacturaIndicacion",
  updateDatosDependientes: "PACIENTE/ModificarDatosDependiente",
  uploadUserImage: "ImagenUsuarioApk/ActualizarImagen",
  AddPrefactura: "PreFactura/Add",
  PdfResultados: "Resultados/ResultadosURLByNoLaboratorios",
};

module.exports = {
  ApiRoutes,
};
