import { createStore } from "vuex";
import { NODE_ENV } from "@/config";

import appModule, { name as appModuleName } from "@/store/modules/app";
import userModule, { name as userModuleName } from "@/store/modules/user";
import createPersistedState from "vuex-persistedstate";

const debug = NODE_ENV !== "production";

// Define los nombres de los módulos
export const modulesName = {
  appModuleName: appModuleName,
  userModuleName: userModuleName,
};

// Crea la instancia de Vuex Store
const store = createStore({
  strict: debug,
  modules: {
    [appModuleName]: appModule,
    [userModuleName]: userModule,
  },
  plugins: [createPersistedState()],
});

export default store;
