import { api } from "@/core/api";
import { modulesName } from "@/store";

const setup = (store) => {
  api.interceptors.request.use(
    (config) => {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, true);
      const token = store.getters[`${modulesName.userModuleName}/accessToken`];
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (res) => {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      return res;
    },
    async (err) => {
      store.dispatch(`${modulesName.appModuleName}/setLoading`, false);
      // const originalConfig = err.config;

      // if (originalConfig.url !== "/auth/signin" && err.response) {
      //   // Access Token was expired
      //   if (err.response.status === 401 && !originalConfig._retry) {
      //     originalConfig._retry = true;

      //     try {
      //       const rs = await axiosInstance.post("/auth/refreshtoken", {
      //         refreshToken: TokenService.getLocalRefreshToken(),
      //       });

      //       const { accessToken } = rs.data;

      //       store.dispatch('auth/refreshToken', accessToken);
      //       TokenService.updateLocalAccessToken(accessToken);

      //       return api(originalConfig);
      //     } catch (_error) {
      //       return Promise.reject(_error);
      //     }
      //   }
      // }

      return Promise.reject(err);
    }
  );
};

export default setup;
