import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Resultados from "../views/Resultados.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Dependientes from "../views/Dependientes.vue";
import ResultadosViewPDF from "../views/ResultadosViewPDF.vue";
import Login from "../views/Login.vue";
import PreFactura from "../views/pre-factura/prefactura.vue";
// import { applyEvents } from "./events";
import { checkPermissionGuard } from "@/guards/check-permission.guard.js";
import PasswordReset from "../views/PasswordReset.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/resultados",
    name: "Resultados",
    component: Resultados,
  },
  {
    path: "/dependientes",
    name: "Dependientes",
    component: Dependientes,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/resultados/pdf",
    name: "Resultados PDF",
    component: ResultadosViewPDF,
  },
  {
    path: "/pre-factura",
    name: "Pre-Factura",
    component: PreFactura,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    component: PasswordReset,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(checkPermissionGuard);

// applyEvents(router);

export default router;