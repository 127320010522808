<template>
  <div
    class="modal fade"
    id="prefacturaModal"
    tabindex="-1"
    aria-labelledby="prefacturaModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="prefacturaModalLabel">
            Nueva Pre-Factura
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab === 'tab1' }"
                href="javascript:void(0)"
                @click="setActiveTab('tab1')"
                >Seleccionar Pacientes</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{
                  active: activeTab === 'tab2',
                  disabled: !isTab1Complete,
                }"
                href="javascript:void(0)"
                @click="setActiveTab('tab2')"
                >Detalles de Pre-Factura</a
              >
            </li>
          </ul>
          <div class="tab-content mt-3">
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'tab1' }"
              id="tab1"
            >
              <form>
                <div class="mb-3">
                  <label for="pacientes" class="form-label"
                    >Seleccione los pacientes que desea incluir en su
                    pre-factura:</label
                  >
                  <div
                    v-for="(paciente, index) in pacientes"
                    :key="index"
                    class="form-check"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'paciente' + index"
                      v-model="selectedPacientes"
                      :value="paciente"
                    />
                    <label class="form-check-label" :for="'paciente' + index"
                      >{{ paciente.NombreCompleto }}
                      <span v-if="paciente.principal">(Principal)</span></label
                    >
                  </div>
                  <label for="dependientes" class="form-label mt-3"
                    >Seleccione los dependientes:</label
                  >
                  <div
                    v-for="(dependiente, index) in dependientes"
                    :key="index"
                    class="form-check"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'dependiente' + index"
                      v-model="selectedDependientes"
                      :value="dependiente"
                    />
                    <label
                      class="form-check-label"
                      :for="'dependiente' + index"
                      >{{ dependiente.NombreCompleto }}</label
                    >
                  </div>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'tab2' }"
              id="tab2"
            >
              <div class="mb-3">
                <label for="tipoCliente" class="form-label"
                  >Tipo de Cliente</label
                >
                <div>
                  <button
                    type="button"
                    class="btn btn-outline-primary me-2"
                    :class="{ active: tipoCliente === 'No Asegurado' }"
                    @click="setTipoCliente('No Asegurado')"
                    @mouseover="changeHoverColor"
                  >
                    No Asegurado
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    :class="{ active: tipoCliente === 'Asegurado' }"
                    @click="setTipoCliente('Asegurado')"
                    @mouseover="changeHoverColor"
                  >
                    Asegurado
                  </button>
                </div>
              </div>
              <div class="mb-3">
                <label for="pacientesSeleccionados" class="form-label"
                  >Pacientes Seleccionados</label
                >
                <div
                  v-for="(paciente, index) in selectedPacientes"
                  :key="index"
                  class="card p-2 mb-2"
                >
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <span>{{ paciente.NombreCompleto }}</span>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="removePaciente(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div class="d-flex flex-wrap align-items-center">
                    <div class="file-upload me-3">
                      <input
                        type="file"
                        :id="'cedula' + index"
                        ref="cedula"
                        style="display: none"
                        @change="uploadFile('cedula', index)"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        @click="triggerUpload('cedula', index)"
                      >
                        Cédula
                      </button>
                      <span v-if="paciente.cedulaFile" class="file-name">{{
                        paciente.cedulaFile.name
                      }}</span>
                    </div>

                    <div class="file-upload me-3">
                      <input
                        type="file"
                        :id="'seguro' + index"
                        ref="seguro"
                        style="display: none"
                        @change="uploadFile('seguro', index)"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        @click="triggerUpload('seguro', index)"
                      >
                        Seguro
                      </button>
                      <span v-if="paciente.seguroFile" class="file-name">{{
                        paciente.seguroFile.name
                      }}</span>
                    </div>

                    <div class="file-upload">
                      <input
                        type="file"
                        :id="'indicacion' + index"
                        ref="indicacion"
                        style="display: none"
                        @change="uploadFile('indicacion', index)"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        @click="triggerUpload('indicacion', index)"
                      >
                        Indicación
                      </button>
                      <span v-if="paciente.indicacionFile" class="file-name">{{
                        paciente.indicacionFile.name
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            @click="goToPreviousTab"
            v-if="activeTab === 'tab2'"
          >
            Anterior
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            @click="goToNextTab"
            v-if="activeTab === 'tab1'"
          >
            Siguiente
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            @click="saveChanges"
            v-if="activeTab === 'tab2'"
          >
            Guardar Cambios
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="successModal"
    tabindex="-1"
    aria-labelledby="successModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="successModalLabel">Guardado con éxito</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Los cambios se guardaron correctamente.</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import DependientesController from "../../../utils/http/Dependientes";
import { apiUrl } from "../../../utils/api/enviroment";
import { ApiRoutes } from "../../../utils/api/api_routes";
import TokenManager from "../../../utils/token_manager";
import bootstrap from "bootstrap/dist/js/bootstrap";
import Swal from "sweetalert2";

const activeTab = ref("tab1");
const isTab1Complete = ref(false);
const selectedPacientes = ref([]);
const selectedDependientes = ref([]);
const tipoCliente = ref("");
const pacientes = ref([]);
const dependientes = ref([]);

const fetchData = async () => {
  try {
    const response = await DependientesController.getDependientes();
    if (response && response.Paciente && response.Dependientes) {
      pacientes.value = [response.Paciente];
      dependientes.value = response.Dependientes;
    } else {
      console.error("Estructura de respuesta inesperada:", response);
    }
  } catch (error) {
    console.error("Error fetching pacientes:", error);
  }
};

onMounted(fetchData);

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const goToNextTab = () => {
  if (selectedPacientes.value.length > 0) {
    selectedPacientes.value = [
      ...selectedPacientes.value,
      ...selectedDependientes.value,
    ];
    selectedDependientes.value = [];
    isTab1Complete.value = true;
    setActiveTab("tab2");
  } else {
    alert("Por favor, seleccione al menos un paciente antes de continuar.");
  }
};

const goToPreviousTab = () => {
  setActiveTab("tab1");
};

const saveChanges = async () => {
  const url = `${apiUrl}/${ApiRoutes.AddPrefactura}`;
  const token = TokenManager.getToken();
  try {
    if (selectedPacientes.value.length == 0) {
      Swal.fire({
        title: "Debe seleccionar al menos un paciente",
        icon: "error",
      });
      return;
    }
    for (const paciente of selectedPacientes.value) {
      const preFacturaData = {
        PacienteId: paciente.PacienteId,
        Identificacion: paciente.Identificacion,
        NombrePaciente: paciente.NombreCompleto,
        NumeroAfiliado: paciente.Seguro,
        Comentario: paciente.Comentario || "",
        Direccion: paciente.Direccion,
        Telefono: paciente.Telefono,
        Movil: paciente.Movil,
        Correo: paciente.Email,
        MunicipioId: paciente.MunicipioID,
        FechaNacimiento: paciente.FechaNacimiento,
        RncCedula: paciente.Identificacion,
        TipoIdentificacion: paciente.TipoDocumentoID || 1,
        Sexo: paciente.Sexo,
      };

      const response = await axios.post(url, preFacturaData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error("Error al guardar cambios.");
      }
    }

    const successModal = document.getElementById("successModal");
    const modal = new bootstrap.Modal(successModal);
    modal.show();

    setTimeout(() => {
      location.reload();
    }, 2000);
  } catch (error) {
    console.error("Error al guardar cambios:", error);
    alert("Hubo un error al guardar los cambios.");
  }
};

const removePaciente = (index) => {
  selectedPacientes.value.splice(index, 1);
};

const triggerUpload = (type, index) => {
  if (type === "cedula") {
    document.getElementById(`cedula${index}`).click();
  } else if (type === "seguro") {
    document.getElementById(`seguro${index}`).click();
  } else if (type === "indicacion") {
    document.getElementById(`indicacion${index}`).click();
  }
};

const uploadFile = (type, index) => {
  const fileInput = document.getElementById(`${type}${index}`);
  const file = fileInput.files[0];

  if (type === "cedula") {
    selectedPacientes.value[index].cedulaFile = file;
  } else if (type === "seguro") {
    selectedPacientes.value[index].seguroFile = file;
  } else if (type === "indicacion") {
    selectedPacientes.value[index].indicacionFile = file;
  }
};

const setTipoCliente = (tipo) => {
  tipoCliente.value = tipo;
};

const changeHoverColor = (event) => {
  event.target.classList.remove("btn-outline-primary");
  event.target.classList.add("btn-outline-success");
};
</script>

<style scoped>
.btn-outline-primary.active,
.btn-outline-primary:hover {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.file-upload {
  position: relative;
}

.file-name {
  display: inline-block;
  margin-left: 10px;
}
</style>
