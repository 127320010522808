<script setup>
import { VuePDF, usePDF } from '@tato30/vue-pdf'

const { pdf } = usePDF('https://ppacienteqa.azurewebsites.net/Resultados/24/24001017751-da81b32c-7bc8-46ff-a78f-ecc32d255e25.pdf')
import UserCard from "./components/UserCard.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 mb-lg" style="margin-top: 10%;">
        <user-card></user-card>
        <div class="card shadow-lg mb-4 glass">
          <div class="card-body center">
            <VuePDF :pdf="pdf" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .center {
    display: block;
    margin: 0 auto;
  }

  .glass {
    background: rgba( 255, 255, 255, 0.4 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4.5px );
-webkit-backdrop-filter: blur( 4.5px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
</style>
