export default {
  userId: ({ user }) => (user === null ? null : user.ID),

  accessToken: (state) => state.accessToken,

  profile({ user }) {
    if (user === null) {
      return null;
    }
    return user;
  },
};
