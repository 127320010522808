import axios from "axios";
import { apiUrl } from "../api/enviroment";
import { ApiRoutes } from "../api/api_routes";
import TokenManager from "../token_manager";
import { useStore } from "vuex";

const DependientesController = {
  async getDependientes() {
    const store = useStore();
    const token = TokenManager.getToken();
    const pacienteId = store.state.$_user.PACIENTE_DATA.PacienteID;
    const url = `${apiUrl}/${ApiRoutes.dependientesGetAll}?PacienteId=${pacienteId}`;

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return { ok: false };
      }
    } catch (error) {
      return { ok: false, error: error.message };
    }
  },

  async updateDependiente(
    Identificacion,
    Sexo,
    TelefonoMovil,
    TelefonoResidencial,
    Correo
  ) {
    const token = TokenManager.getToken();
    const store = useStore();
    const pacienteId = store.state.$_user.PACIENTE_DATA.PacienteID;
    const url = `${apiUrl}/${ApiRoutes.updateDatosDependientes}`;

    try {
      const response = await axios.post(
        url,
        {
          PacienteId: pacienteId,
          Identificacion: Identificacion,
          Sexo: Sexo,
          TelefonoMovil: TelefonoMovil,
          TelefonoResidencial: TelefonoResidencial,
          Correo: Correo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.status === 200;
    } catch (error) {
      return false;
    }
  },
};

export default DependientesController;
